//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    parent: {
      type: String,
      default: 'Plan'
    }
  },
  data() {
    var _window$sessionStorag, _window$sessionStorag2;
    const promocodeAllowed = !!window.sessionStorage.getItem("promocode:show");
    return {
      promocodeAllowed,
      promocode: promocodeAllowed && ((_window$sessionStorag = window.sessionStorage.getItem("promocode:code")) === null || _window$sessionStorag === void 0 || (_window$sessionStorag2 = _window$sessionStorag.toUpperCase) === null || _window$sessionStorag2 === void 0 ? void 0 : _window$sessionStorag2.call(_window$sessionStorag)),
      plans: null,
      calculation: null

      //promocodeType: window.sessionStorage.getItem("promocode:type"),
      //chinaCodeClass: window.sessionStorage.getItem("chinaCode:class"),
      //chinaCodeReason: window.sessionStorage.getItem("chinaCode:reason"),
    };
  },

  async created() {
    //async beforeCreate() {
    //console.log('PN.67: -> this.calculate()')
    await this.calculate();
  },
  methods: {
    async calculate() {
      if (!this.promocode) {
        return;
      }
      this.plans = await this.$store.dispatch("billing/getPlans");
      this.calculation = await this.$store.dispatch("billing/calculateOrder", {
        paymentMethod: 'card',
        //!&: ???

        type: "first",
        items: [{
          plan: this.plans[0],
          months: 1,
          discounts: [{
            type: "promocode",
            code: this.promocode
          }]
        }]
      });

      //console.log('PN.97: this.calculation=', this.calculation)
    }
  },

  computed: {
    result() {
      var _this$calculation;
      //console.log('PN.102: this.promocode=', this.promocode)
      if (!this.promocode) {
        return null;
      }

      /* //это percent promo-code
      // //console.log('PN.104: CHECK : !this.promocode || !this.calculation')
      // if( !this.promocode || !this.calculation ){
      //     //console.log('PN.106: !this.promocode || !this.calculation')
      //     return null;
      // }
      */

      //console.log('PN.110: this.parent=', this.parent)
      if (this.parent == 'Register') {
        return {
          // class: window.sessionStorage.getItem("promocode:reason")=='success' ? 'success' : 'error',
          // reason: window.sessionStorage.getItem("promocode:reason"),
          class: 'success'
        };
      }

      //*== далее this.parent=='Plan'

      //это chinaCode
      //console.log('PN.115: sessionStorage.getItem(promocode.type)=', window.sessionStorage.getItem("promocode.type"))
      if (window.sessionStorage.getItem("promocode.type") == 'china') {
        let res = {
          type: window.sessionStorage.getItem("promocode.type"),
          class: window.sessionStorage.getItem("promocode.class"),
          reason: window.sessionStorage.getItem("promocode.reason")
        };
        //console.log('PN.121: res=', res)
        return res;
      }

      //*== это percent promo-code
      //this.calculate();

      //console.log('PN.139: this.promocode=', this.promocode)
      //console.log('PN.139: this.calculation=', this.calculation)

      if (!this.promocode || !this.calculation) {
        //console.log('PN.147: !this.promocode || !this.calculation')
        return null;
      }
      let appliedPromocode = null;
      let declinedPromocode = null;

      //console.log('PN.154: this.calculation.items=', this.calculation.items)
      //console.log('PN.156: this.calculation=', this.calculation);

      (((_this$calculation = this.calculation) === null || _this$calculation === void 0 ? void 0 : _this$calculation.items) || []).forEach(item => {
        let itemAppliedPromocode = item.appliedDiscounts.find(i => i.type === "promocode");
        if (itemAppliedPromocode) {
          appliedPromocode = itemAppliedPromocode;
        }
        let itemDeclinedPromocode = item.declinedDiscounts.find(i => i.type === "promocode");
        if (itemDeclinedPromocode) {
          declinedPromocode = itemDeclinedPromocode;
        }
      });

      //console.log('PN.171: declinedPromocode=', declinedPromocode)
      //console.log('PN.172: appliedPromocode=', appliedPromocode)

      if (declinedPromocode) {
        var _declinedPromocode;
        //console.log('PN.177: declinedPromocode=', declinedPromocode, ' type=percent, class=error, reason=', declinedPromocode?.reason)

        return {
          type: "percent",
          class: "error",
          reason: (_declinedPromocode = declinedPromocode) === null || _declinedPromocode === void 0 ? void 0 : _declinedPromocode.reason
        };
      }
      if (appliedPromocode) {
        //console.log('PN.188: appliedPromocode=', appliedPromocode, ' type=percent, class=success, reason=', appliedPromocode.reason)

        return {
          type: "percent",
          class: "success",
          percent: appliedPromocode.percent,
          text: `Шикарно! Скидка ${appliedPromocode.percent}%`,
          reason: "success"
        };
      }
      return null;
    }
  }
};